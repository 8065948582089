@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "~@fancyapps/fancybox/src/css/core.css";
@import "~@glidejs/glide/src/assets/sass/glide.core";
@import "~@glidejs/glide/src/assets/sass/glide.theme";

.alert {
  border-width: 1px;
  border-left-width: 5px;
  border-right-width: 5px;
}

.bg-cover {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  &-fixed {
    background-attachment: fixed;
  }
}

.btn {
  &-facebook {
    @include button-variant($facebook, $facebook);
  }
  &-linkedin {
    @include button-variant($linkedin, $linkedin);
  }
  &-pinterest {
    @include button-variant($pinterest, $pinterest);
  }
  &-twitter {
    @include button-variant($twitter, $twitter);
  }
  &-whatsapp {
    @include button-variant($whatsapp, $whatsapp);
  }
}

.dropdown-menu {
  border-bottom: 5px solid $secondary;
  opacity: .9;
}

.nav-tabs .nav-link {
  border-bottom: 3px solid transparent;
  &.active {
    border-bottom-color: $primary;
  }
}

.square {
  display: block;
  text-align: center;

  &-50 {
    font-size: 15px;
    height: 50px;
    line-height: 50px;
    width: 50px;
    min-width: 50px;
  }
  &-100 {
    font-size: 30px;
    height: 100px;
    line-height: 100px;
    width: 100px;
    min-width: 100px;
  }
}

#table-combo tr {
  & > td:first-child,
  & > th:first-child {
    border-left: none;
  }
  & > td:last-child,
  & > th:last-child {
    border-right: none;
  }
}

.table-responsive .table tr {
  th, td { white-space: nowrap }
}

.timeline {
  list-style-type: none;
  position: relative;

  &:before {
    background: darken($body-bg, 10%);
    content: ' ';
    display: inline-block;
    height: 100%;
    left: 9px;
    position: absolute;
    width: 2px;
    z-index: 400;
  }

  > li {
    position: relative;

    &:last-child {
      border-bottom: none;
    }

    &:before {
      background: white;
      border: 2px solid $primary;
      border-radius: 50%;
      content: ' ';
      display: inline-block;
      height: 20px;
      left: -32px;
      top: 35px;
      transform: translateY(-10px);
      position: absolute;
      width: 20px;
      z-index: 400;

      @media (min-width: breakpoint-min(md)) {
        top: 40px;
      }
    }
  }
}
