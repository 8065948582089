$primary: #2a276a;
$secondary: #30b4c1;
$danger: #ed1b24;

$facebook: #3b5998;
$linkedin: #0077b5;
$pinterest: #e60023;
$twitter: #1da1f2;
$whatsapp: #25d366;

$font-family-sans-serif: "Roboto", sans-serif;

$input-bg: #fafafa;
$input-border-color: darken($input-bg, 10%);
$input-focus-box-shadow: none;

$line-height-base: 1.5;

$nav-tabs-border-width: 0;
$nav-tabs-link-active-bg: transparent;

$navbar-light-toggler-border-color: none;
